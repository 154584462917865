.image-gallery-icon{
    filter: none !important;
    color: #ccc !important;
}
.image-gallery-icon:hover{
    color: #f55733!important;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg
{
    height: 60px !important;
    width: 40px !important;
}

.image-gallery-icon.image-gallery-fullscreen-button{
    bottom: 100% !important;
    right: 15px !important;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:active, .image-gallery-thumbnail:focus{
    border: 2px solid #ccc !important;
}

@media screen and (max-width: 992px) {
    .portfolio_container .underline_with_heading::before,
    .portfolio_container .underline_with_heading::after{
        display: none;
    }
}